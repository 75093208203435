<template>
  <div>
    <template v-if="checkedSource == checkInputSource">
      <trash v-on="$listeners" :result="result"></trash>
    </template>
  </div>
</template>
<script>
import trash from "@/commonComponents/dynamic/trash.vue";
export default {
  components: { trash },
  props: ["result", "fieldAttributes"],

  computed: {
    checkedSource() {
      let checkedSource;
      if (typeof this.result.fields.source != "undefined") {
        checkedSource = this.result.valueLine.source;
      }
      if (typeof this.result.fields.remove != "undefined") {
        checkedSource = this.result.fields.remove.source;
      }
      if (
        typeof checkedSource != "undefined" &&
        checkedSource == "t_responseTemplates"
      ) {
        checkedSource == "t_templates";
      }
      //console.log("FA---",this.fieldAttributes.source);
      //console.log("RE-SV--",this.result.valueLine.source);
      //console.log("RE-REMOVE-SV--",this.result.fields.remove.source);
      return checkedSource;
    },
    checkInputSource() {
      let checkedSource = this.fieldAttributes.source;
      if (
        typeof checkedSource != "undefined" &&
        checkedSource == "t_responseTemplates"
      ) {
        checkedSource == "t_templates";
      }
      return checkedSource;
    },
  },
};
</script>